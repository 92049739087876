.loading{
    display: block !important;
}
.loader{
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: white;
    z-index: 1;
}

.loader-items{
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 40vh;
}

.lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #C33149;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #C33149 transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes loading-text
  {
    0% {
        content: "Loading"
    }
    
    25%{
        content: "Loading."
    }
    50%{
        content: "Loading.."
    }
    75%{
        content: "Loading..."
  }
}
  