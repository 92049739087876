#Header{
    position: fixed;
    width: 100%;
    transition: all 0.5s;
    z-index: 1;
    background-color: rgba(255,255,255,.4);
}
.main-menu{
    padding-bottom: 20px;
    padding-top: 20px;
}
#mobile-body-overly {
    width: 100%;
    height: 100%;
    z-index: 997;
    top: 0;
    left: 0;
    position: fixed;
    background: rgba(0,0,0,.7);
    display: none;
}

.withShadow{
    box-shadow: 0px 0px 10px -3px black;
    background-color: white !important;
    /*Todo: Add Background*/
}

#logo{
    width: 55px;
}

#logo a img {

    max-width: 100%;
    height: auto;
}

/*TESTTT*/ 

.TestSection{
    background-image: url("../../Assets/Images/Picture.jpg");
    height: calc(100vh);
    display: flex;
    background-position: center;
    background-size: cover;
}