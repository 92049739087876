
.services-title{
    background-image: url("/src/Assets/Images/SkillsBack.png");
}
.service-header h2{
    text-transform: uppercase;
    font-size: 2.5rem;
    color: black;
    text-align: center;
}
.service-header{
    display: flex;
    padding-top: 25px;
    padding-bottom: 25px;    
    flex-direction: column;
    align-items: center;
}
.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}
.single-services {
    text-align: center;
    margin-top: 60px;
}
.service-img{
    height: 100px;
}
.single-services h4 {
    margin-top: 30px;
    margin-bottom: 20px;
    font-size: 1.5rem;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    font-family:'Times New Roman', Times, serif;
    color: black;
    cursor: pointer;
}
.single-services p {
    max-width: 318px;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.single-services a{
    color: black;
    text-decoration: none;
}

.single-services:hover h4{
    color: #ec5b53;
    font-size: 1.7rem;
    transition: 0.3s ease 0s;
}
.title-space{
    height: 50px;
    margin-left: auto;
    margin-right: auto;
}
