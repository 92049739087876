.home-about-right h6 {
    margin-bottom: 30px;
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 10px;
}
.about-fullscreen{
    height: auto;
}
.about-fullscreen div h2{
  font-size: 2.5rem;
  color: black;
  letter-spacing: 2px;
}

.about-btn{
    height: 50px;
}
.img-fluid{    
    box-shadow: 0px 0px 5px 1px black;
}
.about-title{
  background-image: url("/src/Assets/Images/AboutImg.png");
}
/*Timeline*/
.timeline-section{
    margin-top: 40px;
}

.timeline-section h1{
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
.timeline {
    position: relative;
    margin: 40px auto auto auto;
    max-width: 1000px;
  }
  
  /* The actual timeline (the vertical ruler) */
  .timeline::after {
    content: '';
    position: absolute;
    width: 6px;
    background-color: #ec5b53;
    top: 0;
    bottom: 0;
    left: 50%;
    margin-left: -3px;
    z-index: -1;
  }
  
  /* Container around content */
  .timeline .container {
    padding: 10px 40px;
    position: relative;
    background-color: inherit;
    width: 50%;
    margin: 0;
  }
  
  /* The circles on the timeline */
  .timeline .container::after {
    content: '';
    position: absolute;
    width: 25px;
    height: 25px;
    right: -13px;
    background-color: white;
    border: 4px solid rgba(246, 113, 113, 1);
    top: 15px;
    border-radius: 50%;
    z-index: 1;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  
  /* Place the container to the left */
  .left {
    left: 0;
  }
  
  /* Place the container to the right */
  .right {
    left: 50%;
  }
  
  /* Add arrows to the left container (pointing right) */
  .left::before {
    content: " ";
    height: 0;
    position: absolute;
    top: 22px;
    width: 0;
    z-index: 1;
    right: 30px;
    border: medium solid black;
    border-width: 10px 0 10px 10px;
    border-color: transparent transparent transparent black;
  }
  
  /* Add arrows to the right container (pointing left) */
  .right::before {
    content: " ";
    height: 0;
    position: absolute;
    top: 22px;
    width: 0;
    z-index: 1;
    left: 30px;
    border: medium solid black;
    border-width: 10px 10px 10px 0;
    border-color: transparent black transparent transparent;
  }
  
  /* Fix the circle for containers on the right side */
  .right::after {
    left: -13px;
  }
  
  /* The actual content */
  .content {
    padding: 20px 30px;
    background-color: white;    
    box-shadow: 0px 0px 5px 1px black;
    position: relative;
    border-radius: 6px;
  }
  
  .content-title{
    display: flex;
    align-items: center;
  }
  .content-title h2{
    font-weight: bold;
    color: #f67171;
    font-size: 1.7rem;
  }
  .content-title h3{
    margin-left: 20px;
    font-size: 1.3rem;
    align-self: flex-start;
  }
  .content-title h4{
    margin-left: 10px;
    font-size: 1.3rem;
    align-self: center;
  }
  /* Media queries - Responsive timeline on screens less than 600px wide */
  @media screen and (max-width: 780px) {
    /* Place the timelime to the left */
    .timeline::after {
    left: 31px;
    } 
    /* Full-width containers */
    .timeline .container {
    width: 100%;
    padding-left: 70px;
    padding-right: 25px;
    }
    
    /* Make sure that all arrows are pointing leftwards */
    .timeline .container::before {
    left: 60px;
    border: medium solid black;
    border-width: 10px 10px 10px 0;
    border-color: transparent black transparent transparent;
    }
  
    /* Make sure all circles are at the same spot */
    .left::after, .right::after {
    left: 18px;
    }
    
    /* Make all right containers behave like the left ones */
    .right {
    left: 0%;
    }
}
@media (max-width: 767px)
{
.home-about-right {
    margin-top: 30px;
}
}