.ContactForm{
    padding-top: 80px;
    padding-bottom: 60px;
    /*background-color: #F2DFE3;*/
}
.contact-caption{
    text-align: center;
}
.contact-caption h3{
    font-size: 48px;
    display: block;
    color: #000a2d;
    font-weight: 600;
    margin-bottom: 40px;
    font-family: "Rufina",serif;
}

.contact-caption b{
    font-weight: bold;
}
.ContactForm .contact-wrapper input {
    background: #fff;
    height: 60px;
    width: 100%;
    padding: 10px 26px;
    border: 0;
    color: black;
    font-weight: 500;
    text-transform: capitalize;
    border-radius: 0;
    border: 0;
    margin-bottom: 30px;
    box-shadow: 1px 1px 3px 1px rgba(1,1,1,.5);
    -webkit-box-shadow:1px 1px 3px 1px rgba(1,1,1,.5);
    -webkit-appearance: none;
}

.ContactForm .contact-wrapper input::placeholder {
    color: #a3a3a3;
    font-weight: 500;
    text-transform: capitalize;
}

.contact-wrapper textarea {
    color: black;
    background: #fff;
    font-weight: 500;
    letter-spacing: 2px;
    text-transform: capitalize;
    height: 160px;
    width: 100%;
    padding: 30px 26px;
    border: 0;
    border-radius: 0;
    border: 0;
    margin-bottom: 30px;
    box-shadow: 1px 1px 3px 1px rgba(1,1,1,.5);
    -webkit-box-shadow:1px 1px 3px 1px rgba(1,1,1,.5);
    -webkit-appearance: none;
}
.contact-wrapper textarea::placeholder {
    color: #a3a3a3;
    font-weight: 500;
    letter-spacing: 2px;
    text-transform: capitalize;
}

.submit-btn2 {
    width: 100%;
    height: 60px;
    padding: 10px 20px;
    border: 0;
    color: #fff;
    text-transform: capitalize;
    cursor: pointer;
    font-size: 16px;
    border-radius: 0;
}


.section-contactform{    
    background-image: url("/src/Assets/Images/ContactBack.jpg");
    z-index: 1;
    padding-top: 100px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    min-height: 100vh;
    box-shadow: 0px 0px 5px 1px black;
    display: flex;
    align-items: center;
}
.contact-form{
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}
.card{    
    padding: 25px;
    background-color: rgba(1,1,1,.7);
}
.card-side{
    background-color: white;
}
.card h2{
    font-size: 2.5rem;
    color: white;
    text-align: center;
}
.contact-inputs{
    display: flex;
    flex-direction: column;
}
.contact-inputs input{
    background: white;
    height: 60px;
    width: 100%;
    padding: 20px 26px;
    border: 0;
    color: black;
    font-weight: 500;
    text-transform: capitalize;
    border-radius: 0;
    border: 0;
    margin-bottom: 30px;
    box-shadow: 1px 1px 3px 1px rgb(1 1 1 / 50%);
    font-family: Poppins, sans-serif;
}
.contact-inputs textarea{
    
    color: black;
    background: white;
    font-weight: 500;
    letter-spacing: 2px;
    text-transform: capitalize;
    height: 160px;
    width: 100%;
    padding: 30px 26px;
    border: 0;
    border-radius: 0;
    border: 0;
    margin-bottom: 30px;
    box-shadow: 1px 1px 3px 1px rgb(1 1 1 / 50%);
}

.contact-inputs input:focus-visible{
    outline: black auto 1px;
}
.contact-inputs textarea:focus-visible{
    outline: black auto 1px;
}
.socials{
    margin-top: auto;
}
.card-side h2{
    margin-top: 60px;
    color: black;
}
.card-side p{
    font-size: 16px;
    text-align: center;
    margin: 15px;
}
.card-side ul{
    display: flex;
    list-style: none;
    justify-content: center;
}
.card-side ul li {
    padding: 15px;
    display: inline-block;
}
.contact-wrapper #message::before{
    content: "\F4C9";
}

.input-error{    
    outline: ridge #ec5b53 1px;
}

.lbl-success{
    visibility: visible !important;
    opacity: 1 !important;
    background-color: rgba(3, 193, 0, 0.5);
    color: black;
}
.lbl-error{
    visibility: visible !important;
    opacity: 1 !important;
    background-color: rgba(211, 18, 18, 0.5);
}
#lblmsg{
    border-radius: 50px 50px 50px 50px;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.8s linear;
    font-size: 1rem;
    width: 100%;
    padding: 15px;
    margin-bottom: 10px;
    color: white;
    text-align: center;
}
@media (max-width:990px) {
    .contact-form{
        flex-direction: column;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 150px;
    }
}