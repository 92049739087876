.section-resume{
    position: relative;
}

.section-resume .container{
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    background-color: white;
    z-index: 1;
}
.section-resume h3{
    color: black;
}

.resume-title{
    background-image: url("/src/Assets/Images/BackTest.png");
}
.resume-title h2{
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    position: absolute;
    font-size: 2.5rem;
    color: rgba(1,1,1, 1);
}
.spacer{
    
    width: 78%;
    color: black;
    background-color: rgba(236, 236, 236, 0.8);
    height: 2px;
    display: block;
    margin: 20px auto 20px auto;
}

.spacer-full{
    width: 100%;
    color: black;
    background-color: rgba(236, 236, 236, 0.8);
    height: 2px;
    display: block;
    margin: 0px auto 20px auto;
}

.resume-head{
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    position: relative;
}

.resume-head .intro-name{
    width: 100%;
    height: 10%;
    flex: 1;
}
.resume-head .intro-name .intro-spacer{
    width: 80%;
    background-color: rgba(50, 50, 5, 1);
    height: 2px;
    display: block;
    margin: 0px auto 20px auto;
}

.intro-desc{    
    margin-left: 50px;
    margin-right: 50px;
    text-align: justify;
}
.intro-desc b{
    font-weight: bold;
}
.intro-desc .head-span{
    display: inline-block;
    height: 20px;
    width: 2px;
    background-color: rgba(1, 1, 1, .6);
}
.desc-head{
    display: flex;
    justify-content: space-between;
    width: auto;
    font-size: 16px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 10px;
}
.resume-head h1{
    margin-top: 20px;
    margin-left: 50px;
}
.resume-img img{
    width: 180px;
    margin-top: 20px;
    border-radius: 60% 60%;
    border: 3px solid rgba(246, 113, 113);
}

.resume-work{
    display: flex;
    width: 100%;
    margin-top: 40px;
    margin-bottom: 40px;
}

.container .resume-work span{
    position: absolute;
    background-color: rgba(236, 236, 236, 1);
    width: 2px;
    height: 100%;
    margin-right: 15px;
}

.work-title-container{
    display: flex;
    flex-direction: column;
    margin-left: 15px;
    width: 88%;
}
.work-title .title{
    display: flex;

}
.work-title .title h2{ 
    margin-right: 10px;
}

.work-title h2{
    font-size: 1.2rem;
    font-weight: bold;
    color: black;
}
.work-title h3{
    font-size: 1.2rem;
    color: #777777;
    align-self: center;
    white-space: nowrap;
}

.work-list h4{
    font-weight: bold;
    font-size: 1.3rem;
    color: black;
}
.work-list ul{
    list-style-type: circle;
}

.work-list ul li{
    color: black;
    font-size: 16px;
    margin-top: 5px;
    margin-bottom: 5px;
}


/*Resume Mini*/
.resume-mini{
    margin-top: 40px;
}

.resume-mini > div{
    margin-right: auto;
    margin-left: auto;
}

.resume-item {
    padding: 30px;
    background: #333;
    -webkit-transition: .3s all ease;
    -o-transition: .3s all ease;
    transition: .3s all ease;
    border: 4px solid transparent;
    border-radius: 7px;
    height: 240px;
}
.resume-item .date {
    display: inline-block;
    margin-bottom: 10px;
    margin-left: 15px;
    font-size: 12px;
    font-weight: 700;
    color: #fff;
}

.resume-item h3 {
    color: rgba(246, 113, 113, 1);
    font-size: 20px;
}
.sub-title{
    color: #C33149 ;
}
.title{
    font-size: 2.5rem;
    color: black;
    letter-spacing: 2px;
}

.btn-div{
    display: flex;
    justify-content: center;
}
.resume-btn{
    margin-top: 15px;
    margin-bottom: 20px;
}
@media screen and (max-width:990px) {
    .resume-head{
        flex-direction: column;
        align-items: center;
    }
    .intro-desc{        
        position: unset;
        margin-left: 15px;
        margin-right: 15px;
    }
    .desc-head{
        width: 100%;
        margin-bottom: 20px;
        flex-direction: column;
    }
    .head-span{
        display: none !important;
    }
    .desc-head div{
        margin-top: 5px;
    }
}
