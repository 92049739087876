body{
    color: #777777;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.625em;
    position: relative;
    /*background-color: #f9f9ff;*/
}
.container{
    width: 100%;    
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
.App{
    width: 100%;
    display: inline;
}
p{
    font-family: "Rubik",sans-serif;
    font-size: 16px;
}
a{
    text-decoration: none;
}
strong{
    font-weight: bold !important;
    font-size: 18px;
}
.primary-btn {
    text-align: center;
    background: -webkit-linear-gradient(0deg, #ec5b53 0%, #C33149 100%);
    line-height: 42px;
    padding-left: 30px;
    padding-right: 30px;
    border: none;
    color: #fff;
    display: inline-block;
    font-weight: 600;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    cursor: pointer;
    position: relative;    
    text-decoration: auto;
}

.primary-btn:hover{
    box-shadow: 0 20px 20px 0 rgba(214, 63, 76, 0.3);
    color:white;
}

.error-page{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
}
.error-section{
    position: relative;
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}
.error-section img{
    max-height: 350px;
    max-width: 350px;
    margin-right: auto;
    margin-left: auto;
}

.title-section{    
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  height: 20vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 40px;
}
.title-section h1{
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    position: absolute;
    font-size: 4vw;
    color: rgba(1,1,1, 0.8);
  }

  @media screen and (max-width:990px) {
    .title-section h1{
    font-size: 6vh;
} 
  }
@media (min-width: 992px)
{
    .container{
        max-width: 890px;       
    }
}

