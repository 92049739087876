.mobile-nav{
    position: absolute;
    background-color: black;
    opacity: 0.6;
}
#mobile-nav{
    position: absolute;
    width: 70px;
    height: 70px;
    right: 0;
    top: 0;
}

.mobile-nav-toggle {
    width: 100px;
    right: 1%;
    z-index: 1;
    border: 0;
    background: none;
    font-size: 21px;
    display: none;
    cursor: pointer;
    position: absolute;
    top: 50%;
}

.mobile-nav-toggle span:first-child{
    top:-8px;
}
.mobile-nav-toggle span:nth-child(2){
    top:0px;
}
.mobile-nav-toggle span:nth-child(3){
    top:8px;
}
.mobile-nav-toggle span{
    margin-right: 0px;
    display: block;
    position: absolute;
    height: 2px;
    width: 25px;
    background: #C33149;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    right: 0;
    margin: auto;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s cubic-bezier(.95,.05,.795,.035);
    transition: .25s cubic-bezier(.95,.05,.795,.035);
}


.close-marker{    
    width: 100px;
    right: 4%;
    z-index: 1;
    border: 0;
    background: none;
    font-size: 21px;
    cursor: pointer;
    position: absolute;
    top: 50%;
}

.close-marker span{
    margin-right: 0px;
    display: block;
    position: absolute;
    height: 2px;
    width: 28px;
    background: #C33149;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    right: 0;
    margin: auto;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
}

.close-marker span:first-child {
    top: 8px;
    width: 0;
    left: 50%;
}

.close-marker span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    width: 28px;
}

.close-marker span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    width: 28px;
}

.close-marker span:nth-child(4) {
    top: 8px;
    width: 0;
    left: 50%;
}


.MobileToggle{
    background-color: rgba(10,10,10,.65);
    height: 100%;
    position: fixed;
    left: -80% !important;
    width: 50%;
    backdrop-filter: blur(4px);
    border-right: 2px solid rgba(255,255,255, 0.2);
    box-shadow: 0px 8px 32px rgb(2 4 28);
    display: flex;    
    align-items: center;
    -webkit-transition: all 1s cubic-bezier(.95,.05,.795,.035);
    transition: all .6s ease;
    -webkit-transition-timing-function: cubic-bezier(.95,.05,.795,.035);
    transition-timing-function: cubic-bezier(.95,.05,.795,.035);
}
.MobileToggle.open{
    left: 0 !important;
}

.MobileToggle .list-container{    
    text-align: center;
    max-width: 1540px;
    
}
.MobileToggle .list-container li{
    margin: 35px 0;
    text-transform: uppercase;
    font-size: 20px;
    overflow: hidden;
    position: relative;
    height: 30px;
}
.MobileToggle .list-container li i{
    position: absolute;
    left: 17%;
    color: white;
    font-size: 22px;
}
.MobileToggle .list-container li a{
    text-decoration: none;
    font-weight: bold;
    top: auto;
    height: 100%;
    left: 0;
    right: 0;
    color: white;
    font-size: 24px;
}

.contact-mob{
    height: auto !important;
}
@media (max-width: 768px)
{    
    .mobile-nav-toggle {
        display: block !important;
    }
    .MobileToggle{
        width: 64%;
    }
}
@media (max-width: 468px)
{    
    .mobile-nav-toggle {
        display: block !important;
    }
    .MobileToggle{
        width: 75%;
    }
}